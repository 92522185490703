import * as React from 'react';
import _ from 'lodash';
import { Button, Col, Row } from 'reactstrap';
import servicesData from '../data/services.json';
import { ExternalLink, MainHeading, Markdown, SubPageLayout, SubSection, SubSections } from '../components';
import FeeTableSection from '../components/RateTableSection/RateTableSection';
import CdnImage from '../components/CdnImage/CdnImage';

export const ServicesPageInternal = (services: typeof servicesData) => {
  const splitServices = _.chunk(services.services, Math.ceil(services.services.length / 2));
  return (
    <SubPageLayout
      title="Services"
      imageSrc={services.headerImageSrc}
    >
      <MainHeading
        label="Services"
      />

      <SubSections>
        {[
          <SubSection key="online-banking" anchor="online-banking" headerLabel="Online Banking">
            <Row>
              <Col className="d-flex flex-column" md={12}>
                <Markdown source={services.onlineBanking} />

                <div className="text-center">
                  <ExternalLink className="btn btn-primary" href="https://bsdc.onlinecu.com/racine">
                    Access Online Banking
                  </ExternalLink>
                </div>
              </Col>
            </Row>

            <Row className="text-center mt-5">
              <Col md={{ offset: 2, size: 4 }}>
                <ExternalLink href="https://play.google.com/store/apps/details?id=com.racine.mobilebanking.live&hl=en_US&gl=US">
                  <i className="fab fa-5x fa-google-play"/>
                </ExternalLink>
              </Col>
              <Col md={{ size: 4 }}>
                <ExternalLink href="https://apps.apple.com/us/app/rmecu/id1072831951">
                  <i className="fab fa-5x fa-app-store-ios"/>
                </ExternalLink>
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="other-services" anchor="other-services" headerLabel="Member Services">
            <Markdown source={services.otherServices} />

            <Row>
              <Col md={6}>
                <ul>
                  {
                    _.map(splitServices[0], (service, i) => (
                      <li key={i}>{service}</li>
                    ))
                  }
                </ul>
              </Col>
              <Col md={6}>
                <ul>
                  {
                    _.map(splitServices[1], (service, i) => (
                      <li key={i}>{service}</li>
                    ))
                  }
                </ul>
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="atm-locator" anchor="atm-locator" headerLabel="Free ATM Locator">
            <Markdown source={services.freeAtmLocator} />

            <Row>
              <Col>
                <div className="d-flex justify-content-center text-center">
                  <ExternalLink href="https://www.allianceone.coop/a1atm/find">
                    <div style={{ maxWidth: 300 }}>
                      <CdnImage className="img-fluid" src="/img/alliance-one.png" alt="Alliance One Network ATM Finder Logo" />
                    </div>
                  </ExternalLink>
                </div>
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="shared-branching" anchor="shared-branching" headerLabel="Shared Branching">
            <Row>
              <Col>
                <Markdown source={services.sharedBranching} />
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="d-flex justify-content-center text-center">
                  <ExternalLink href="https://co-opcreditunions.org/locator/?ref=co-opsharedbranch.org&sc=1&utm_source=co-opfs.org&utm_medium=cta&utm_campaign=b2bsite&utm_term=locatorpage">
                    <div style={{ maxWidth: 300 }}>
                      <CdnImage className="img-fluid" src="/img/logo_co-op.png" alt="Co-op Financial Services" />
                    </div>
                  </ExternalLink>
                </div>
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="calculators" anchor="calculators" headerLabel="Calculators">
            <Row>
              <Col>
                <Markdown source={services.calculators} />
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button color="primary" tag={ExternalLink} href="https://www.greenpath.com/wellness/resources/calculators/financial-and-loan-calculators/?utm_source=partner&utm_medium=credit_union">
                  Loan and Savings Calculators
                </Button>
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="order-checks" anchor="order-checks" headerLabel="Order Checks">
            <Row>
              <Col>
                <Markdown source={services.orderChecks} />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center" md={{ size: 6, offset: 3 }}>
                <div style={{ maxWidth: 300 }}>
                  <ExternalLink href="https://www.bank-a-count.com/financial/ReorderChecks/e9b70d1c-83e9-4c92-acb8-c0085ae34924">
                    <CdnImage className="img-fluid" src="/img/order-checks.png" alt="Order Checks Button" />
                  </ExternalLink>
                </div>
              </Col>
            </Row>
          </SubSection>,
          <SubSection key="report-lost-stolen-card" anchor="report-lost-stolen-card" headerLabel="Report a Lost/Stolen Card">
            <Markdown source={services.reportLostStolenCard} />
          </SubSection>,
          <SubSection key="fraud-identity-theft" anchor="fraud-identity-theft" headerLabel="Fraud/Identity Theft">
            <Markdown source={services.fraudIdentityTheft} />
          </SubSection>,
          <SubSection key="service-fees" anchor="service-fees" headerLabel="Service Fees">
            <FeeTableSection label="Checking Fees" fees={services.checkingFees} />
            <FeeTableSection label="Savings Fees" fees={services.savingsFees} />
            <FeeTableSection label="IRA Account Fees" fees={services.iraAccountFees} />
            <FeeTableSection label="Other Fees and Charges" fees={services.otherFeesAndCharges} />
            <FeeTableSection label="General Account Maintenance Fees" fees={services.generalAccountMaintenanceFees} />
            <div className="disclaimer">
              <Markdown source={services.generalAccountMaintenanceFeesDisclaimer} />
            </div>
          </SubSection>,
        ]}
      </SubSections>
    </SubPageLayout>
  );
};

export default () => <ServicesPageInternal {...servicesData} />;
