import React, { ReactNode } from 'react';
import { Table } from 'reactstrap';
import _ from 'lodash';
import classNames from 'classnames';

interface Props {
  label: ReactNode;
  fees: { description?: string; fee?: string; }[];
}

const FeeTableSection = ({ label, fees }: Props) => {
  return (
    <>
      <h5>{label}</h5>
      <Table borderless size="sm">
        <tbody>
          {
            _.map(fees, (fee, i) => {
              const isSubRow = fee.description && !fee.fee;
              return (
                <tr className="white-space-pre" key={i}>
                  <td className={classNames({ 'is-sub-row': isSubRow })} style={{ width: '75%' }}>{fee.description}</td>
                  <td>{fee.fee}</td>
                </tr>
              );
            })
          }
        </tbody>
      </Table>
    </>
  );
};

export default FeeTableSection;
